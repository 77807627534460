.menu__list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 22px;
}
.menu__item {
  text-transform: uppercase;
  font-weight: 600;
  max-width: 100%;
}
.menu__item a {
  padding: 15px 25px;
  border-radius: 5px;
}
.active {
  background-color: rgb(184, 217, 252);
  color: red;
}
@media (max-width: 1200px) {
  .menu__list {
    font-size: 20px;
  }
  .menu__item a {
    padding: 15px 10px;
  }
}
/* @media (max-width: 1000px) {
  .menu__list {
    font-size: 18px;
  }
  .menu__item a {
    padding: 10px 18px;
    border-radius: 5px;
  }
} */
@media screen and (max-width: 960px) {
  /* меню */

  .menu {
    display: flex;
    position: absolute;
    right: -280px;
    top: 0;
    width: 280px;
    background-color: rgb(0, 128, 255);
    visibility: hidden;
    padding-top: 60px;
    transition: transform 0.5s, visibility 0.5s;
    z-index: 1;
    border-radius: 5px;
  }
  .menu__list {
    display: block;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .menu__link {
    display: inherit;
    color: #ffffff;
    padding: 20px 0px;
    font-family: Roboto, sans-serif;
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-weight: 500;
  }
  .menu__item a {
    padding: 25px 35px;
  }
  .active {
    background-color: transparent;
  }
}
@media (max-width: 480px) {
  .menu {
    display: flex;
    position: absolute;
    right: -240px;
    top: 0;
    width: 240px;
    background-color: rgb(0, 128, 255);
    visibility: hidden;
    padding-top: 60px;
    transition: transform 0.5s, visibility 0.5s;
    z-index: 1;
    border-radius: 5px;
  }
}
