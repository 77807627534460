.header__social-link-item:hover {
  border-radius: 40%;
  box-shadow: -4px 4px 5px #5da1cb, 4px 4px 5px #5da1cb, 4px -4px 5px #5da1cb,
    -4px -4px 5px #5da1cb;
}
.header__social-link-item {
  display: flex;
  align-self: center;
  transform: scale(0.7);
}

@media (max-width: 360px) {
  .header__social-link-item {
    display: flex;
    align-self: center;
    transform: scale(0.5);
  }
}
